import React from "react"
import styled from "styled-components"

const FotterButton = (props) => {
  const { onClickCancel, onClickSave, onClickDelete } = props
  return (
    <Container>
      {onClickCancel !== undefined && (
        <Box onClick={() => onClickCancel()}>
          <Text>취소</Text>
        </Box>
      )}
      {onClickSave !== undefined && (
        <SaveBox onClick={() => onClickSave()}>
          <SaveText>저장</SaveText>
        </SaveBox>
      )}
      {onClickDelete !== undefined && (
        <Box onClick={() => onClickDelete()}>
          <Text>삭제</Text>
        </Box>
      )}
    </Container>
  )
}
export default FotterButton

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`
const SaveBox = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 50px;
  border-radius: 4px;
  background-color: #00adee;
  margin-left: 12px;
  margin-right: 12px;
`

const Box = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #ced1da;
  margin-left: 12px;
  margin-right: 12px;
`
const SaveText = styled.p`
  font-family: NanumSquareBold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.48px;
  color: #fff;
`
const Text = styled.p`
  font-family: NanumSquareRegular;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.48px;
  color: #35363a;
`
