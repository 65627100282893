// 기사등록/수정 페이지
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import SEO from "../seo"
import BranchContainer from "../../containers/branchContainer"
import Layout from "../component/common/layout"
import PageName from "../component/common/pageName"
import FotterButton from "../component/common/fotterButton"
import InputBox from "../component/common/inputBox"
import BasicPopup from "../../popups/basicPopup"

const Driver_new = (props) => {
  const { driver, postDriver, patchDriver, deleteDriver, getDriverCheckId } = props
  const hasKeys = Object.keys(driver).length > 0
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [deletePopup, setDeletePopup] = useState(false)
  const [driverName, setDriverName] = useState("")
  const [region, setRegion] = useState("")
  const [area, setArea] = useState("")
  const [phoneNo, setPhoneNo] = useState("")
  const [userId, setUserId] = useState("")
  const [pw, setPw] = useState("")
  const [idCheck, setIdCheck] = useState(null)

  useEffect(() => {
    if (Object.keys(driver).length === 0) return

    setDriverName(driver.driverName)
    setRegion(driver.region)
    setArea(driver.area)
    setPhoneNo(driver.phoneNo)
    setUserId(driver.userId)
    setIdCheck(true)
    setPw("")
  }, [])

  async function onClickPost() {
    if (driverName === "" || region === "" || area === "" || phoneNo === "" || userId === "" || pw === "")
      return setPopup({ open: true, text: "빈 칸을 모두 채워주세요." })
    else if (!idCheck) return setPopup({ open: true, text: "중복된 아이디입니다." })
    const result = await postDriver({
      driverName: driverName,
      region: region,
      area: area,
      phoneNo: phoneNo,
      id: userId,
      password: pw,
      isOkcheon: 0,
    })
    if (result) {
      navigate(-1)
    }
  }

  async function onClickPatch() {
    if (driverName === "" || region === "" || area === "" || phoneNo === "" || userId === "" || pw === "")
      return setPopup({ open: true, text: "빈 칸을 모두 채워주세요." })
    else if (!idCheck) return setPopup({ open: true, text: "중복된 아이디입니다." })
    const result = await patchDriver({
      driverSeq: driver.driverSeq,
      driverName: driverName,
      region: region,
      area: area,
      phoneNo: phoneNo,
      id: userId,
      password: pw,
      isOkcheon: 0,
    })
    if (result) {
      navigate(-1)
    }
  }

  async function onClickDelete() {
    await deleteDriver({ driverSeq: driver.driverSeq })
  }

  return (
    <Layout navi={"기사관리"}>
      <SEO title={"기사관리"} />
      <PageName title={["기사관리", "지사기사등록"]}></PageName>
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: "" })} content={popup.text} selectedValue={["확인"]} />
      <BasicPopup
        open={deletePopup}
        onClose={(value) => {
          if (value === "확인") {
            onClickDelete()
          }
          setDeletePopup(false)
        }}
        content={"삭제하시겠습니까?"}
        selectedValue={["취소", "확인"]}
      />

      <Section>
        <div style={{ display: "flex" }}>
          <InputBox label="기사명" value={driverName} setValue={setDriverName}></InputBox>
          <InputBox label="관할구역" marginLeft={12} value={region} setValue={setRegion}></InputBox>
          <InputBox label="지역" marginLeft={12} value={area} setValue={setArea}></InputBox>
          <InputBox label="휴대폰번호" marginLeft={12} value={phoneNo} setValue={setPhoneNo}></InputBox>
        </div>
      </Section>
      <Line></Line>
      <div style={{ display: "flex" }}>
        <InputBox
          label="아이디"
          fullWidth={true}
          value={userId}
          setValue={setUserId}
          onFocusOut={async () => {
            const result = await getDriverCheckId(userId)
            if (result === 1) setIdCheck(true)
            else setIdCheck(false)
          }}
        ></InputBox>
        <InputBox label="비밀번호" isPassword={true} fullWidth={true} marginLeft={10} value={pw} setValue={setPw}></InputBox>
      </div>
      <IdCheck display={idCheck == null ? "none" : "block"}>{idCheck ? "사용할 수 있는 아이디입니다." : ""}</IdCheck>
      <Line></Line>
      {!hasKeys ? (
        <FotterButton onClickCancel={() => navigate(-1)} onClickSave={onClickPost}></FotterButton>
      ) : (
        <FotterButton onClickCancel={() => navigate(-1)} onClickSave={onClickPatch} onClickDelete={() => setDeletePopup(true)}></FotterButton>
      )}
    </Layout>
  )
}

export default BranchContainer(Driver_new)

const Section = styled.section`
  display: flex;
  margin-top: 28.3px;
`

const Line = styled.div`
  width: 1200px;
  height: 4px;
  background-color: #fafafb;
  margin-top: 20px;
  margin-bottom: 17px;
`

const IdCheck = styled.p`
  display: ${(props) => props.display};
  font-family: "NanumSquareRegular";
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.36px;
  color: #00adee;
  margin-top: 1.4px;
`
