import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Dialog } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  container: {
    "& .MuiDialog-paperFullWidth": {
      display: "flex",
      flexDirection: "column",
      width: "400px",
      height: "585px",
      border: "1px solid #000000",
      borderRadius: "4px",
    },
    "& .MuiPaper-elevation24": {
      boxShadow: "none",
    },
    "&.last": {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(0,0,0,0.5)",
      },
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "#00000000",
    },
  },
}))

const ModalPopup = (props) => {
  const classes = useStyles()
  const { item, idx, open, onClose, selectedValue } = props

  const handleClose = (value) => {
    onClose(value)
  }

  return (
    <Container>
      <Dialog
        className={`${classes.container} ${idx === 0 ? "last" : ""}`}
        style={{ marginTop: 10 * idx, marginLeft: 10 * idx }}
        open={open}
        onClose={() => handleClose()}
        fullWidth={true}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          {item.images.length > 0 ? (
            <Img src={item.images[0].imageUrl} alt={"공지 이미지"} />
          ) : (
            <ImgNull>
              <p>이미지 없음</p>
            </ImgNull>
          )}
        </div>
        <div style={{ flex: 1, padding: "12px 40px 36.4px" }}>
          <Title>{item.title}</Title>
          <Content>{`${item.content}`}</Content>
        </div>
        <ButtonView>
          {selectedValue.map((item, idx) => {
            return (
              <Button key={idx} one={selectedValue.length === 2 && idx === 0} onClick={() => handleClose(item)}>
                {item}
              </Button>
            )
          })}
        </ButtonView>
      </Dialog>
    </Container>
  )
}

ModalPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedValue: PropTypes.array.isRequired,
}

export default ModalPopup

const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 585px;
  box-sizing: border-box;
`

const Img = styled.img`
  width: 360px;
  height: 270px;
  object-fit: cover;
`

const ImgNull = styled.section`
  background-color: #dbdbdb;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 360px;
  height: 270px;
  & > p {
    color: #000;
    font-size: 14px;
    font-family: NanumSquareBold;
    line-height: 22.05px;
    letter-spacing: -0.42px;
  }
`

const Title = styled.p`
  color: #2d2f31;
  font-size: 14px;
  font-family: NanumSquareBold;
  line-height: 22.05px;
  letter-spacing: -0.42px;
`

const Content = styled.p`
  color: #2d2f31;
  font-size: 14px;
  font-family: NanumSquareRegular;
  line-height: 22.05px;
  letter-spacing: -0.42px;
  word-break: break-all;
`

const ButtonView = styled.section`
  display: flex;
  height: 50px;
`

const Button = styled.button`
  background-color: #ffffff;
  width: 100%;
  padding: 0px;
  border-top: 1px solid #dddde5;
  border-radius: 0;
  color: ${(props) => (props.one ? "#5e6a79" : "#00adee")};
  font-size: 14px;
  font-family: NanumSquareExtraBold;
  line-height: 24.45px;
  letter-spacing: -0.42px;
`
