// 행낭관리 페이지
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import BranchContainer from "../../containers/branchContainer"
import Layout from "../component/common/layout"
import moment from "moment"
import SEO from "../seo"
import PageName from "../component/common/pageName"
import DatePicker from "../component/common/datePicker"
import Button from "../component/common/button"
import Search from "../component/common/search"
import Pagination from "../component/common/pagination"
import BasicPopup from "../../popups/basicPopup"
import { GetStatus } from "../../utils/util"
const PouchManage = (props) => {
  const { pouchTracks, pouchTracksTotal, getPouchTracks } = props
  const [popup, setPopup] = useState(false)
  const [messageIndex, setMessageIndex] = useState(0)
  const messageArr = ["", "날짜를 다시 확인해주세요.", "검색항목을 입력하세요", "검색어를 두자이상 입력해주세요"]
  const [startDate, setStartDate] = useState(moment().startOf("months"))
  const [endDate, setEndDate] = useState(moment().endOf("months"))
  const [searchIndex, setSearchIndex] = useState(0)
  const [searchText, setSearchText] = useState("")
  const [current, setCurrent] = useState(1)
  const [signPopup, setSignPopup] = useState({ open: false, imageUrl: null })
  useEffect(() => {
    getData()
  }, [current])

  const getData = async () => {
    let temp = {
      page: current - 1,
      count: 20,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    }
    if (searchText.length >= 2 && searchIndex !== 0) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    await getPouchTracks({ ...temp })
  }

  // 검색
  const onClickSearch = async () => {
    if (!moment(startDate).isBefore(moment(endDate))) {
      setMessageIndex(1)
      setPopup(true)
      return
    }
    if (searchIndex === 0) {
      setMessageIndex(2)
      setPopup(true)
      return
    } 
    // else if (searchText.length < 2) {
    //   setMessageIndex(3)
    //   setPopup(true)
    //   return
    // }

    let temp = {
      page: 0,
      count: 20,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    }
    // if (searchText.length >= 2 && searchIndex !== 0) {
    if (searchIndex !== 0) {
      temp = { ...temp, searchType: searchIndex, search: searchText.length > 0 ? searchText : '' }
    }
    await getPouchTracks({ ...temp })

    setCurrent(1)
  }
  //조회
  const onClickLookUp = async () => {
    if (!moment(startDate).isBefore(moment(endDate))) {
      setMessageIndex(1)
      setPopup(true)
      return
    }
    let temp = {
      page: 0,
      count: 20,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    }
    if (searchText.length >= 2 && searchIndex !== 0) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    await getPouchTracks({ ...temp })
  }

  return (
    <Layout navi={"행낭관리"}>
      <SEO title={"행낭관리"} />
      <BasicPopup
        open={signPopup.open}
        onClose={() => setSignPopup({ open: false, imageUrl: null })}
        imageUrl={signPopup.imageUrl}
        selectedValue={["확인"]}
      />
      <BasicPopup open={popup} onClose={() => setPopup(false)} content={messageArr[messageIndex]} selectedValue={["확인"]} />
      <PageName title={["행낭관리"]}></PageName>
      <SectionName>행낭관리</SectionName>
      <Section>
        <DatePicker
          label={"스캔일자"}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          onClick={onClickLookUp}
        />
        <Search
          item={["선택", "행낭번호", "발신거래처지점명", "수신거래처지점명", "발신지사", "수신지사", "스캔담당", "위치", "상태"]}
          index={searchIndex}
          setIndex={setSearchIndex}
          text={searchText}
          setText={setSearchText}
          onClickSearch={onClickSearch}
        />
      </Section>
      <Section1>
        <table style={{ marginBottom: 43.4 }}>
          <thead>
            <tr>
              <th>번호</th>
              <th>행낭코드</th>
              <th>발신 거래처 지점명</th>
              <th>수신 거래처 지점명</th>
              <th>발신지사</th>
              <th>수신지사</th>
              <th>스캔일자</th>
              <th>스캔담당</th>
              <th>위치</th>
              <th>상태</th>
              <th>사진서명</th>
            </tr>
          </thead>
          <tbody>
            {pouchTracks.map((v, i) => {
              return (
                <tr key={i}>
                  <td>{pouchTracksTotal - (current - 1) * 20 - i}</td>
                  <td>{v.pouch === null ? '' : v.pouch.pouchNo}</td>
                  <td>{v.pouch === null ? '' : v.pouch.sendPoint.pointName}</td>
                  <td>{v.pouch === null ? '' : v.pouch.recvPoint.pointName}</td>
                  <td>{v.pouch === null ? '' : v.pouch.sendPoint.branch === null ? '' : v.pouch.sendPoint.branch.branchName}</td>
                  <td>{v.pouch === null ? '' : v.pouch.recvPoint.branch === null ? '' : v.pouch.recvPoint.branch.branchName}</td>
                  <td>{v.regDatetime}</td>
                  <td>{v.scanName}</td>
                  <td>
                    {v.addr}&nbsp;{v.addrDetail}
                  </td>
                  <td>{GetStatus(v.status)}</td>
                  <td>
                    <Button
                      onClick={() => {
                        setSignPopup({ open: true, imageUrl: v.photoUrl || v.signUrl })
                      }}
                      style={{ height: "30px" }}
                    >
                      {"확인"}
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </Section1>
      <Pagination current={current} setCurrent={setCurrent} total={pouchTracksTotal} />
    </Layout>
  )
}

export default BranchContainer(PouchManage)

const SectionName = styled.p`
  margin-bottom: 24.4px;
  margin-top: 20px;
  font-size: 24px;
  font-weight: 600;
  font-family: NanumSquareBold;
  line-height: 42px;
  letter-spacing: -0.72px;
`

const Section = styled.section`
  display: flex;
  justify-content: space-between;
`

const Section1 = styled.section`
  margin-top: 21px;
`
