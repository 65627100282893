// 기사관리 페이지
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import SEO from "../seo"
import { OnClickExcelSave } from "../../utils/util"
import BranchContainer from "../../containers/branchContainer"
import Layout from "../component/common/layout"
import PageName from "../component/common/pageName"
import Button from "../component/common/button"
import Search from "../component/common/search"
import Pagination from "../component/common/pagination"
import BasicPopup from "../../popups/basicPopup"

const DriverManage = (props) => {
  const { drivers, driversTotal, getDrivers, initDriver, getDriver, getDownloadExcel, info } = props
  const [searchIndex, setSearchIndex] = useState(0)
  const [searchText, setSearchText] = useState("")
  const [current, setCurrent] = useState(1)
  const [popup, setPopup] = useState({ open: false, message: "" })

  useEffect(() => {
    getData()
  }, [current])

  const getData = async () => {
    let temp = { page: current - 1, count: 20 }
    if (searchText.length >= 2 && searchIndex !== 0) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    await getDrivers({ ...temp })
  }

  //검색
  const onClickSearch = async () => {
    if (searchIndex === 0) {
      setPopup({ open: true, message: "검색항목을 입력하세요" })
      return
    } 
    // else if (searchText.length < 2) {
    //   setPopup({ open: true, message: "검색어를 두자이상 입력해주세요" })
    //   return
    // }

    let temp = { page: 0, count: 20 }
    // if (searchText.length >= 2 && searchIndex !== 0) {
    if (searchIndex !== 0) {
      temp = { ...temp, searchType: searchIndex, search: searchText.length > 0 ? searchText : '' }
    }
    await getDrivers({ ...temp })
    setCurrent(1)
  }

  //등록
  const onClickRegister = async () => {
    if (info.authDriver === "0") {
      setPopup({ open: true, message: "기사등록 권한이 없습니다" })
      return
    }
    await initDriver()
    navigate("/app/driverNew")
  }
  //엑셀저장
  const onClickExcelSave = async () => {
    let temp = {}
    if (searchText.length >= 2 && searchIndex !== 0) {
      temp = { searchType: searchIndex, search: searchText }
    }
    const result = await getDownloadExcel(temp)
    console.log(result)
    OnClickExcelSave({ result: result, fileName: "기사리스트" })
  }

  return (
    <Layout navi={"기사관리"}>
      <SEO title={"기사관리"} />
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, message: "" })} content={popup.message} selectedValue={["확인"]} />
      <PageName title={["기사관리"]}></PageName>
      <SectionName>기사관리</SectionName>
      <Box display="flex" justifyContent={"space-between"}>
        <Search
          item={["선택", "기사명", "아이디", "관할구역", "지역", "휴대폰번호", "등록자"]}
          index={searchIndex}
          setIndex={setSearchIndex}
          text={searchText}
          setText={setSearchText}
          onClickSearch={onClickSearch}
        />
        <Box justifyContent={"space-between"}>
          <Button onClick={onClickExcelSave}>{"엑셀 저장"}</Button>
          <Button marginLeft={6} onClick={onClickRegister}>
            {"등록"}
          </Button>
        </Box>
      </Box>
      <Section>
        <table stlye={{ marginBottom: 43.4 }}>
          <thead>
            <tr>
              <th>번호</th>
              <th>기사명</th>
              <th>아이디</th>
              <th>관할구역</th>
              <th>지역</th>
              <th>휴대폰번호</th>
              <th>등록일자</th>
              <th>등록자</th>
            </tr>
          </thead>
          <tbody>
            {drivers
              .sort((a, b) => new Date(b.regDatetime) - new Date(a.regDatetime))
              .map((v, i) => (
                <tr key={i}>
                  <td>{driversTotal - (current - 1) * 10 - i}</td>
                  <td>
                    <button
                      onClick={async () => {
                        if (info.authDriver === "0") {
                          setPopup({ open: true, message: "기사수정 권한이 없습니다" })
                          return
                        }
                        await getDriver({ driverSeq: v.driverSeq })
                        navigate("/app/driverNew")
                      }}
                    >
                      {v.driverName}
                    </button>
                  </td>
                  <td>{v.userId}</td>
                  <td>{v.region}</td>
                  <td>{v.area}</td>
                  <td>{v.phoneNo}</td>
                  <td>{v.regDatetime}</td>
                  <td>{v.registName}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </Section>
      <Pagination current={current} setCurrent={setCurrent} total={driversTotal} />
    </Layout>
  )
}

export default BranchContainer(DriverManage)

const SectionName = styled.p`
  margin-top: 25px;
  margin-bottom: 24.4px;
  font-size: 24px;
  font-family: NanumSquareBold;
`

const Section = styled.section`
  margin-top: 21px;
  margin-bottom: 40px;
`

const Box = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
