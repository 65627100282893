import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const { signIn } = actions.SignAction
const { getInfo } = actions.BranchAction
// redux state > component mapping
const mapStateToProps = (state) => ({})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  signIn: (param) => dispatch(signIn(param)),
  getInfo: () => dispatch(getInfo()),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
