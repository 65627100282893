import React from "react"
import styled from "styled-components"
import BlackLogo from "../../../images/blackLogo.png"

const Footer = () => {
  return (
    <Container>
      <FlexView>
        <InfoText>
          {`사업자 : 한국통상물류주식회사 
            우편 : 02) 304-3812
            주소 : 경기도 고양시 일산동구 장항동 노첨길 74
            TEL : 105-87-13460
            copyrightⓒ 2020 by 한국통상물류.com all rights reserved`}
        </InfoText>
        <img style={{}} src={BlackLogo} alt={"로고 이미지"} />
      </FlexView>
    </Container>
  )
}

export default Footer

const Container = styled.div`
  background-color: #eeeef3;
  width: 100%;
  height: 200px;
`
const FlexView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
`
const InfoText = styled.p`
  color: #828291;
  font-size: 13px;
  font-family: NanumSquareRegular;
  line-height: 20px;
  letter-spacing: -0.39px;
  white-space: pre-line;
`
