import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const {
  getInfo,
  getPouchTracks,
  getFreightTracks,
  getDrivers,
  initDriver,
  getDriver,
  postDriver,
  patchDriver,
  deleteDriver,
  getDriverCheckId,
  getDownloadExcel,
  getBoards,
  setPopups,
} = actions.BranchAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  info: state.BranchReducer.info,
  pouchTracks: state.BranchReducer.pouchTracks,
  pouchTracksTotal: state.BranchReducer.pouchTracksTotal,
  freightTracks: state.BranchReducer.freightTracks,
  freightTracksTotal: state.BranchReducer.freightTracksTotal,
  //
  drivers: state.BranchReducer.drivers,
  driversTotal: state.BranchReducer.driversTotal,
  driver: state.BranchReducer.driver,
  //
  boards: state.BranchReducer.boards,
  popups: state.BranchReducer.popups,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  getInfo: (param) => dispatch(getInfo(param)),
  getPouchTracks: (param) => dispatch(getPouchTracks(param)),
  getFreightTracks: (param) => dispatch(getFreightTracks(param)),
  //
  getDrivers: (param) => dispatch(getDrivers(param)),
  initDriver: (param) => dispatch(initDriver(param)),
  getDriver: (param) => dispatch(getDriver(param)),
  postDriver: (param) => dispatch(postDriver(param)),
  patchDriver: (param) => dispatch(patchDriver(param)),
  deleteDriver: (param) => dispatch(deleteDriver(param)),
  getDriverCheckId: (param) => dispatch(getDriverCheckId(param)),
  getDownloadExcel: (param) => dispatch(getDownloadExcel(param)),
  getBoards: (param) => dispatch(getBoards(param)),
  setPopups: (param) => dispatch(setPopups(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
