import React, { useEffect } from "react"
import moment from "moment"
import SEO from "../seo"
import BranchContainer from "../../containers/branchContainer"
import Layout from "../component/common/layout"
import ModalPopup from "../../popups/modalPopup"

const Main = (props) => {
  const { boards, popups, getBoards, setPopups } = props

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    await getBoards()
  }

  return (
    <Layout navi={"메인"}>
      <SEO title={"메인"} />
      {boards
        .filter((i) => JSON.parse(`[${localStorage.getItem(moment().format("YYYY-MM-DD"))}]`).indexOf(i.boardSeq) === -1)
        .map((i, idx) => {
          if (popups.length > 0) {
            return (
              <ModalPopup
                key={idx}
                boards={boards}
                item={i}
                idx={idx}
                open={popups[idx]}
                onClose={(value) => {
                  if (value === "닫기") {
                    const temp = [...popups]
                    temp[idx] = false
                    setPopups(temp)
                  } else if (value === "오늘하루보지않기") {
                    const temp = [...popups]
                    temp[idx] = false
                    setPopups(temp)

                    // 오늘하루보지않기 적용
                    localStorage.removeItem(moment().add(-1, "days").format("YYYY-MM-DD"))
                    if (localStorage.getItem(moment().format("YYYY-MM-DD")) !== null) {
                      localStorage.setItem(moment().format("YYYY-MM-DD"), `${localStorage.getItem(moment().format("YYYY-MM-DD"))}, ${i.boardSeq}`)
                    } else localStorage.setItem(moment().format("YYYY-MM-DD"), `${i.boardSeq}`)
                  }
                }}
                selectedValue={["오늘하루보지않기", "닫기"]}
              />
            )
          }
        })}

      <div style={{ width: "100%", height: "100%" }}></div>
    </Layout>
  )
}

export default BranchContainer(Main)
