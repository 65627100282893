import React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../components/privateRoute"
import Page404 from "./404"
import Root from "../components/page/root"
import Main from "../components/page/main"
import PouchManage from "../components/page/pouchManage"
import DriverManage from "../components/page/driverManage"
import DriverNew from "../components/page/driverNew"
import FreightManage from "../components/page/freightManage"
import Login from "../components/page/login"
import "../components/layout.css"

const App = () => {
  return (
    <Router basepath="/app" style={{ width: "100%", height: "100%" }}>
      <Root path="/" />
      <Main path="/main" />
      <Login path="/login" />
      <PrivateRoute path="/pouchManage" component={PouchManage} />
      <PrivateRoute path="/driverManage" component={DriverManage} />
      <PrivateRoute path="/driverNew" component={DriverNew} />
      <PrivateRoute path="/freightManage" component={FreightManage} />
      {/* 설정 이외의 경로로 들어갈 경우 404 페이지 띄움 */}
      <Page404 path="/*" />
    </Router>
  )
}

export default App
