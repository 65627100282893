// 로그인 페이지
import React, { useState } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import SEO from "../seo"
import SignContainer from "../../containers/signContainer"
import Footer from "../component/common/footer"
import BasicPopup from "../../popups/basicPopup"
import LoginLogo from "../../images/loginLogo.png"
import User from "../../images/user.png"
import Pw from "../../images/pw.png"

const Login = (props) => {
  const { signIn, getInfo } = props
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [id, setId] = useState("")
  const [pw, setPw] = useState("")
  const [focus, setFocus] = useState(-1)
  const [isPressEnter, setIsPressEnter] = useState(false)
  const onKeyPress = (e) => {
    if (isPressEnter) return
    if (e.key === "Enter") {
      setIsPressEnter(true)
      onClickLogin()
    }
  }
  const onKeyUp = (e) => {
    if (e.key === "Enter") {
      setIsPressEnter(false)
    }
  }
  const onClickLogin = async () => {
    if (id === "" || pw === "") {
      setPopup({ open: true, text: "아이디와 비밀번호를 다시 확인해주세요." })
    } else {
      const result = await signIn({ id: id, password: pw })
      if (result) {
        await getInfo()
        navigate("/app/main")
      } else setPopup({ open: true, text: "아이디와 비밀번호를 다시 확인해주세요." })
    }
  }
  return (
    <Container>
      <SEO title={"로그인"} />
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: "" })} content={popup.text} selectedValue={["확인"]} />
      <LoginContainer>
        <Logo src={LoginLogo} alt={"로고 이미지"} />
        <InputBox1 borderColor={focus === 0 ? "#35363a" : "#dddde5"}>
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <img style={{ width: "14px", height: "14px", margin: "20px 7.5px 20px 20px" }} src={User} alt={"로그인 이미지"} />
            <input
              onFocus={() => setFocus(0)}
              onBlur={() => setFocus(-1)}
              type={"text"}
              placeholder={"아이디를 입력해주세요."}
              value={id}
              onChange={(e) => setId(e.target.value)}
              onKeyPress={onKeyPress}
              onKeyUp={onKeyUp}
            />
          </div>
        </InputBox1>
        <InputBox2 borderColor={focus === 1 ? "#35363a" : "#dddde5"}>
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <img style={{ width: "13px", height: "16px", margin: "19px 7.5px 19px 21px" }} src={Pw} alt={"비밀번호 이미지"} />
            <input
              onFocus={() => setFocus(1)}
              onBlur={() => setFocus(-1)}
              type={"password"}
              placeholder={"비밀번호를 입력해주세요."}
              value={pw}
              onChange={(e) => setPw(e.target.value)}
              onKeyPress={onKeyPress}
              onKeyUp={onKeyUp}
            />
          </div>
        </InputBox2>
        <Button onClick={onClickLogin}>{"로그인"}</Button>
      </LoginContainer>
      <Footer />
    </Container>
  )
}

export default SignContainer(Login)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
  width: 200px;
  height: auto;
  margin-bottom: 30.9px;
`

const InputBox1 = styled.div`
  width: 340px;
  height: 54px;
  border: 1px solid;
  border-radius: 4px;
  border-color: #dddde5;
  margin-bottom: 10px;
  border: 1px solid ${(props) => props.borderColor};
  font-family: NanumSquareReguler;
  & input {
    flex: 1;
  }
`

const InputBox2 = styled.div`
  width: 340px;
  height: 54px;
  border: 1px solid;
  border-radius: 4px;
  border-color: #dddde5;
  margin-bottom: 20px;
  border: 1px solid ${(props) => props.borderColor};
  font-family: NanumSquareReguler;
  & input {
    flex: 1;
  }
`

const Button = styled.button`
  width: 340px;
  height: 54px;
  border-radius: 4px;
  background-color: #00adee;
  font-family: NanumSquareBold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.48px;
  color: #ffffff;
`
