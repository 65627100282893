import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import dropIcon from "../../../images/drop.png"
import leftIcon from "../../../images/left.png"
import rightIcon from "../../../images/right.png"
import calendar_up from "../../../images/calendar_up.png"
import calendar_down from "../../../images/calendar_down.png"
const CalendarPopup = (props) => {
  const { open, date, setDate, setCalendarPopup, noSelectable } = props
  const [monthView, setMonthView] = useState(false)
  const [yearView, setYearView] = useState(-1)
  const [tempDate, setTempDate] = useState(moment())
  //onClick ...
  const onClickToday = () => {
    setDate(moment())
    setCalendarPopup({ open: false, date: null, setDate: () => {} })
  }
  const onClickUnSelect = () => {
    setDate(null)
    setCalendarPopup({ open: false, date: null, setDate: () => {} })
  }
  //end onClick...
  useEffect(() => {
    setMonthView(false)
    setYearView(-1)
    if (open !== false) setTempDate(moment)
  }, [open])

  function generate() {
    const startWeek = tempDate.clone().startOf("month").week()
    const endWeek = tempDate.clone().endOf("month").week() === 1 ? 53 : tempDate.clone().endOf("month").week()

    let calendar = []

    for (let week = startWeek; week <= endWeek; week++) {
      calendar.push(
        <div
          key={week}
          style={{
            backgroundColor: "#fff",
            display: "flex",
          }}
        >
          {Array(7)
            .fill(0)
            .map((n, i) => {
              let current = tempDate
                .startOf("month")
                .clone()
                .week(week)
                .startOf("week")
                .add(n + i, "day")

              //let isSelectedDay = date !== null ? (date.format("YYYYMMDD") === current.format("YYYYMMDD") ? true : false) : false
              let isThisMonth = current.format("MM") === tempDate.format("MM") ? true : false

              return (
                <button
                  key={i}
                  onClick={() => {
                    if (isThisMonth) {
                      setDate(current)
                      setCalendarPopup({ open: false, date: null, setDate: () => {} })
                    }
                  }}
                >
                  <DayBox>
                    <WeekText color={isThisMonth ? "#35363a" : "#acb0bb"}>{current.format("D")}</WeekText>
                  </DayBox>
                </button>
              )
            })}
        </div>
      )
    }
    return calendar
  }

  return (
    <CalendarContainer>
      <MonthBox open={monthView} onMouseDown={(e) => e.preventDefault()}>
        {Array(2050 - 2008 + 1)
          .fill(0)
          .map((v, i) => {
            const year = i + 2008
            return (
              <YearBox key={year}>
                <YearButton onClick={() => setYearView(yearView === year ? -1 : year)}>
                  <p>{year}</p>
                  {yearView === year ? (
                    <img src={calendar_up} alt={"위쪽화살표"} style={{ marginRight: "10px" }} />
                  ) : (
                    <img src={calendar_down} alt={"아래쪽화살표"} style={{ marginRight: "10px" }} />
                  )}
                </YearButton>
                <div style={{ display: yearView === year ? "block" : "none", width: "240px", height: "auto", padding: 20 }}>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => (
                    <MonthItem
                      key={month}
                      backColor={tempDate.get("year") === year && tempDate.get("month") + 1 === month ? "#00adee" : "#fff"}
                      color={tempDate.get("year") === year && tempDate.get("month") + 1 === month ? "#fff" : "#35363a"}
                      onClick={() => setTempDate(moment(year + "." + month))}
                    >
                      {month}월
                    </MonthItem>
                  ))}
                </div>
              </YearBox>
            )
          })}
      </MonthBox>
      <CalendarBox open={open} onMouseDown={(e) => e.preventDefault()}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <MonthButton onClick={() => setMonthView(!monthView)}>
            <p>{moment(tempDate).format("YYYY년 MM월")}</p> <img style={{ width: "8px", height: "6px" }} src={dropIcon} alt={"드롭 아이콘"} />
          </MonthButton>
          <div style={{ display: "flex" }}>
            <button style={{ marginRight: "6px" }} onClick={() => setTempDate(moment(tempDate).add(-1, "months"))}>
              <img style={{ width: "25px", height: "25px" }} src={leftIcon} alt={"왼쪽 아이콘"} />
            </button>
            <button onClick={() => setTempDate(moment(tempDate).add(1, "months"))}>
              <img style={{ width: "25px", height: "25px" }} src={rightIcon} alt={"오른쪽 아이콘"} />
            </button>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: 15 }}>
          {["일", "월", "화", "수", "목", "금", "토"].map((i, idx) => (
            <WeekBox key={idx}>
              <WeekText>{i}</WeekText>
            </WeekBox>
          ))}
        </div>
        {generate()}
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <TodayButton onClick={onClickToday}>오늘 선택</TodayButton>
        </div>
        {noSelectable && (
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <TodayButton onClick={onClickUnSelect}>선택안함</TodayButton>
          </div>
        )}
      </CalendarBox>
    </CalendarContainer>
  )
}
export default CalendarPopup

const CalendarContainer = styled.div`
  background-color: #fff;
  position: absolute;
  display: flex;
  top: 40px;
  left: 0;
  min-height: 280px;
  z-index: 1000;
`
const MonthBox = styled.div`
  display: ${(props) => (props.open ? "block" : "none")};
  width: 240px;
  height: auto;
  margin-right: 5px;
  border: 1px solid #dbdee5;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  max-height: 343px;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const YearBox = styled.div`
  position: relative;
  width: 240px;
`
const YearButton = styled.button`
  background-color: #eef2f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 25px;
  margin-bottom: 6px;
  &:last-child {
    margin-bottom: 0;
  }
`
const MonthItem = styled.button`
  background-color: ${(props) => props.backColor || "#fff"};
  width: 45px;
  height: 25px;
  margin-right: 6px;
  margin-bottom: 6px;
  border: 1px solid #dbdee5;
  border-radius: 4px;
  color: ${(props) => props.color || "#35363a"};
  font-size: 12px;
  font-family: NanumSquareRegular;
  line-height: 21px;
  letter-spacing: -0.36px;
  &:nth-child(4),
  &:nth-child(8),
  &:nth-child(12) {
    margin-right: 0;
  }
  &:nth-child(9),
  &:nth-child(10),
  &:nth-child(11),
  &:nth-child(12) {
    margin-bottom: 0;
  }
`
const CalendarBox = styled.div`
  display: ${(props) => (props.open ? "block" : "none")};
  width: 240px;
  min-height: 280px;
  padding: 20px;
  border: 1px solid #dbdee5;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
`
const MonthButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  height: 30px;
  & > p {
    color: #35363a;
    font-size: 13px;
    font-family: NanumSquareBold;
    line-height: 22.8px;
    letter-spacing: -0.39px;
  }
`
const DayBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${200 / 7}px;
  height: ${200 / 7}px;
`
const WeekBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${200 / 7}px;
`
const WeekText = styled.p`
  color: ${(props) => props.color || "#35363a"};
  font-size: 12px;
  font-family: NanumSquareRegular;
  line-height: 21px;
  letter-spacing: -0.36px;
`
const TodayButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 25px;
  margin-top: 7px;
  border: 1px solid #dbdee5;
  border-radius: 4px;
  color: #35363a;
  font-size: 12px;
  font-family: NanumSquareRegular;
  line-height: 21px;
  letter-spacing: -0.36px;
`
