import React, { useState } from "react"
import styled from "styled-components"
import dropIcon from "../../../images/drop.png"
import searchIcon from "../../../images/search.png"

const Search = (props) => {
  const { index, setIndex, text, setText, item, onClickSearch } = props
  const [open, setOpen] = useState(false)
  const [isPressEnter, setIsPressEnter] = useState(false)
  const onKeyPress = (e) => {
    if (isPressEnter) return
    if (e.key === "Enter") {
      setIsPressEnter(true)
      onClickSearch()
    }
  }
  const onKeyUp = (e) => {
    if (e.key === "Enter") {
      setIsPressEnter(false)
    }
  }
  return (
    <Container>
      <SelectBox>
        <StyledSelect onClick={() => setOpen(!open)} onBlur={() => setOpen(false)}>
          <StyledP select={item[index]}>{item[index]}</StyledP>
          <img style={{ width: "8px", height: "6px", marginRight: "6px" }} src={dropIcon} alt={"드롭 아이콘"} />
        </StyledSelect>
        <OptionBox open={open}>
          {item.map((option, optionIndex) => (
            <Option
              key={optionIndex}
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => {
                setIndex(optionIndex)
                setOpen(false)
              }}
            >
              {option}
            </Option>
          ))}
        </OptionBox>
      </SelectBox>

      <SearchBox>
        <InputStyled
          placeholder={"검색어를 두자 이상 입력하세요."}
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyPress={onKeyPress}
          onKeyUp={onKeyUp}
        />
        <SearchBtn onClick={onClickSearch}>
          <img style={{}} src={searchIcon} alt={"검색 아이콘"} />
        </SearchBtn>
      </SearchBox>
    </Container>
  )
}

export default Search

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 410px;
  height: 40px;
  border: 1px solid #dbdee5;
  border-radius: 4px;
`

// SELECT
const SelectBox = styled.div`
  position: relative;
  width: 100px;
  height: 40px;
  z-index: 100;
`
const StyledSelect = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
`
const OptionBox = styled.div`
  background-color: #fff;
  position: absolute;
  overflow-y: scroll;
  top: 42px;
  left: 0;
  display: ${(props) => (props.open ? "block" : "none")};
  width: 100%;
  min-width: 100px;
  max-height: 160px;
  height: auto;
  border: 1px solid #dbdee5;
  border-radius: 4px;
`
const Option = styled.button`
  overflow-x: hidden;
  display: block;
  width: 100%;
  /* min-width: 100px; */
  height: 40px;
  margin: 0px;
  padding: 0px;
  padding-left: 10px;
  border-top: 1px solid #dbdee5;
  box-sizing: border-box;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:first-child {
    border-top: none;
  }
`

// SEARCH
const SearchBox = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-left: 1px solid #dbdee5;
`
const InputStyled = styled.input`
  background-color: ${(props) => (props.readOnly ? "#eef2f7" : "#fff")};
  padding-left: 10px;
  width: 232px;
  font-family: NanumSquareRegular;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.42px;
  color: #35363a;
`
const SearchBtn = styled.button`
  background-color: #00adee;
  width: 40px;
  height: 40px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`
const StyledP = styled.p`
  margin-left: 10px;
  font-family: NanumSquareRegular;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.42px;
  color: ${(props) => (props.select === "선택" ? "#acb0bb" : "#35363a")};
`
